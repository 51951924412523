import React from "react"
import Landing from "./views/Landing"
import { ReactElement } from "react"
import { SvgIcon } from "@material-ui/core"

export interface ViewRoute {
    title: string
    element?: ReactElement
    path: string
    secure?: boolean
    removeFromMenu?: boolean
    icon?: typeof SvgIcon
    views?: ViewRoute[]
}

export interface LayoutRoute {
    path: string
    secure?: boolean
    views: ViewRoute[]
}

export interface Routes {
    [layout: string]: LayoutRoute
}

const routes: Routes = {
    main: {
        path: "/",
        views: [
            {
                title: "Hjem",
                element: <Landing />,
                path: "",
            },
        ],
    },
}

export default routes
