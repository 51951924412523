import { createMuiTheme, Theme, makeStyles, ThemeOptions } from "@material-ui/core"
import { red } from "@material-ui/core/colors"

export const defaultTheme: Theme = createMuiTheme()

const theme: ThemeOptions = {
    palette: {
        primary: {
            main: "#FFA800",
        },
        secondary: {
            main: "#6C648B",
        },
        error: red,
    },
    typography: {
        fontFamily: ["Roboto Condensed", "Roboto", "Helvetica", "sans-serif"].join(", "),
        body1: {
            fontSize: "1.1rem",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "1rem",
            },
        },
        h1: {
            fontSize: "5.6rem",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "5.2rem",
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: "3rem",
            },
        },
        h2: {
            fontSize: "3.35rem",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "2.95rem",
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: "2.45rem",
            },
        },
        h3: {
            fontSize: "2.4rem",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "2.0rem",
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: "1.6rem",
            },
        },
        h5: {
            fontSize: "1.5rem",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "1.3rem",
            },
            [defaultTheme.breakpoints.down("xs")]: {
                fontSize: "1.1rem",
            },
        },
        subtitle1: {
            opacity: 0.7,
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                borderRadius: 200,
            },
        },
        MuiCard: {
            root: {
                boxShadow: defaultTheme.shadows[4],
            },
        },
        MuiTypography: {
            h1: {
                fontSize: "6rem",
            },
        },
    },
}

export const useGlobalStyles = makeStyles(() => ({
    "@global": {
        ".section": {
            padding: `${defaultTheme.spacing(8)}px ${defaultTheme.spacing(2)}px`,
            marginRight: "auto",
            marginLeft: "auto",
            maxWidth: 1140,
            [defaultTheme.breakpoints.down("sm")]: {
                maxWidth: 600,
            },
            "&.full": {
                maxWidth: "unset !important",
            },
        },
        ".square": {
            paddingTop: "100%",
            position: "relative",
            "&> img": {
                objectFit: "cover",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
        },
        ".imageShadow": {
            boxShadow: defaultTheme.shadows[6],
        },
    },
}))

export default theme
