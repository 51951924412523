import { makeStyles, Theme, AppBar, Toolbar } from "@material-ui/core"
import React, { useCallback, useEffect, useState } from "react"
import cn from "classnames"
import LogoFullTrans from "../../assets/img/logo/logo_full_trans.png"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: 98,
        overflow: "hidden",
        backgroundColor: "transparent",
        transition: theme.transitions.create("all", {
            duration: theme.transitions.duration.complex,
        }),
    },
    headerHidden: {
        height: "0 !important",
    },
    content: {
        paddingTop: `${theme.spacing(2)}px !important`,
        paddingBottom: `${theme.spacing(2)}px !important`,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        color: "black",
    },
    scrolledDown: {
        height: 76,
        backgroundColor: theme.palette.common.white,
    },
    logo: {
        width: "auto",
        height: "100%",
        "& > img": {
            float: "left",
            height: "100%",
        },
    },
}))

export interface HeaderProps {}

const scrollDelta = 500

const Header = (props: HeaderProps) => {
    const classes = useStyles()
    const [hasScrolledDown, setHasScrolledDown] = useState<boolean>(false)

    const [lastPageOffset, setLastPageOffset] = useState<number>(0)
    const [isHeaderHidden, setIsHeaderHidden] = useState<boolean>(false)
    const [didScroll, setDidScroll] = useState<boolean>(false)

    const hasScrolled = useCallback(() => {
        const currentOffset = window.pageYOffset

        if (Math.abs(lastPageOffset - currentOffset) <= scrollDelta) {
            return
        }

        if (currentOffset > lastPageOffset) {
            setIsHeaderHidden(true)
        } else {
            setIsHeaderHidden(false)
        }

        setLastPageOffset(currentOffset)
    }, [lastPageOffset])

    const handleScroll = useCallback(() => {
        setDidScroll(true)
        if (window.pageYOffset > 0) {
            setHasScrolledDown(true)
        } else {
            setHasScrolledDown(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        const scrollInterval = setInterval(() => {
            if (didScroll) {
                hasScrolled()
                setDidScroll(false)
            }
        }, 150)

        return () => {
            window.removeEventListener("scroll", handleScroll)
            clearInterval(scrollInterval)
        }
    }, [didScroll, handleScroll, hasScrolled])

    return (
        <AppBar
            elevation={hasScrolledDown ? 1 : 0}
            position={"fixed"}
            className={cn(classes.root, {
                [classes.scrolledDown]: hasScrolledDown,
                [classes.headerHidden]: isHeaderHidden,
            })}
        >
            <Toolbar className={cn(classes.content, "section")}>
                <div className={classes.logo}>
                    <img src={LogoFullTrans} alt={"Odecon - IT-løsninger for alle"} />
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default Header
