import React from "react"
import Router from "../../utils/Router"
import Header from "../../parts/Header"
import Footer from "../../parts/Footer/Footer"

const Main = () => {
    return (
        <React.Fragment>
            <Header />
            <Router layout={"main"} />
            <Footer />
        </React.Fragment>
    )
}

export default Main
