import React, { ReactNode, useEffect } from "react"
import { ViewRoute } from "../../routes"

interface ViewWrapperProps {
    children?: ReactNode | ReactNode[]
    routeConfig: ViewRoute
}

const ViewWrapper = (props: ViewWrapperProps) => {
    const { children, routeConfig } = props

    useEffect(() => {
        document.title = `${routeConfig.title} | Ødegaard Consulting – IT-løsninger for alle`
    })
    return <>{children}</>
}

export default ViewWrapper
