import React, { forwardRef, ReactNode, Ref } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import cn from "classnames"

export interface ParallaxProps {
    children?: ReactNode | ReactNode[]
    className?: string
    backgroundImage?: string
    overlayGradient?: string
    height?: number | string
    ref?: React.Ref<any>
}

const useStyles = makeStyles((theme: Theme) => ({
    root: (props: ParallaxProps) => {
        const backgrounds = []
        if (props.overlayGradient) {
            backgrounds.push(props.overlayGradient)
        }
        if (props.backgroundImage) {
            backgrounds.push(`url("${props.backgroundImage}")`)
        }
        const height =
            typeof props.height === "string"
                ? props.height
                : typeof props.height === "number"
                ? `${100 * props.height}vh`
                : undefined
        return {
            height,
            backgroundImage: backgrounds.join(","),
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            [theme.breakpoints.down("xs")]: {
                backgroundAttachment: "scroll",
            },
        }
    },
}))

const Parallax = (props: ParallaxProps, ref: Ref<any>) => {
    const { children, className } = props
    const classes = useStyles(props)

    return (
        <div ref={ref} className={cn(className, classes.root)}>
            {children}
        </div>
    )
}

export default forwardRef(Parallax)
