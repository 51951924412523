import cn from "classnames"
import LogoFullTrans from "../../assets/img/svg/LogoFullTrans"
import { Typography, useTheme } from "@material-ui/core"
import * as contactInfo from "../../constants/contactInfo"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => {
    return {
        fullCenter: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
    }
})

const Footer = () => {
    const theme = useTheme()
    const classes = useStyles()
    return (
        <footer className={cn("section", classes.fullCenter)}>
            <LogoFullTrans width={350} />
            <Typography style={{ marginTop: theme.spacing(4) }}>
                {contactInfo.OFFICIAL_NAME} | Org.nr.{" "}
                <a
                    href={
                        "https://www.proff.no/selskap/%C3%B8degaard-consulting/bergen/it-konsulenter-og-r%C3%A5dgivning/IDMUJZS03DC/"
                    }
                >
                    {contactInfo.ORG_NR}
                </a>{" "}
                | <a href={`mailto:${contactInfo.CONTACT_MAIL}`}>{contactInfo.CONTACT_MAIL}</a> |{" "}
                <a href={`tel:${contactInfo.CONTACT_PHONE}`}>{contactInfo.CONTACT_PHONE}</a>
            </Typography>
        </footer>
    )
}

export default Footer
