import React from "react"
import "./App.css"
import MainLayout from "./layouts/Main"
import routes from "./routes"
import theme, { defaultTheme, useGlobalStyles } from "./styles/themes/odecon"

import { Route, Routes } from "react-router-dom"
import axios from "axios"

// Material UI
import { CssBaseline, createMuiTheme, ThemeProvider } from "@material-ui/core"

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const createTheme = (customization: any) => {
    return createMuiTheme({
        ...theme,
        palette: {
            ...theme.palette,
            type: customization.dark ? "dark" : "light",
            background: customization.dark
                ? {
                      default: "#212121",
                      paper: "#333333",
                  }
                : defaultTheme.palette.background,
        },
    })
}

const App = () => {
    useGlobalStyles()

    const prefersDarkMode = false
    return (
        <ThemeProvider
            theme={createTheme({
                ...{},
                dark: prefersDarkMode,
            })}
        >
            <CssBaseline>
                <div id={"App"}>
                    <Routes>
                        <Route path={routes.main.path} element={<MainLayout />} />
                    </Routes>
                </div>
            </CssBaseline>
        </ThemeProvider>
    )
}

export default App
