import React, { FormEvent, useState } from "react"
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
    useTheme,
    fade,
} from "@material-ui/core"
import axios from "axios"
import cn from "classnames"
import CloseUpComputerImage from "../../assets/img/stock-photos/close-up-computer.jpg"
import ComputerWithGraph from "../../assets/img/stock-photos/computer-with-graph.jpg"
import Parallax from "../../components/Parallax"
import ComputerWithHomepageImage from "../../assets/img/stock-photos/computer-with-homepage.jpg"
import GroupIcon from "@material-ui/icons/Group"
import PhonelinkIcon from "@material-ui/icons/Phonelink"
import ChartIcon from "@material-ui/icons/ShowChart"
import * as contactInfo from "../../constants/contactInfo"
import LogoFullTrans from "../../assets/img/svg/LogoFullTrans"

const useStyles = makeStyles((theme: Theme) => ({
    root: {},

    newHomepageSection: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    },
    whyChooseUsSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        "& .MuiGrid-item": {
            "& .MuiCard-root": {
                height: "100%",
                margin: "auto",
                backgroundColor: theme.palette.primary.main,
                "& .MuiCardContent-root": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                },
                "& svg": {
                    fontSize: 72,
                    margin: `${theme.spacing(2)}px auto`,
                },
            },
        },
    },
    fullCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    contactForm: {
        width: "100%",
        maxWidth: "600px !important",
        "& .MuiCard-root": {
            padding: theme.spacing(4),
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(1),
            },
            "& .MuiCardActions-root": {
                justifyContent: "flex-end",
            },
        },
    },
}))

const Landing = () => {
    const classes = useStyles()
    const theme = useTheme()
    const [formValues, setFormValues] = useState<{
        description?: string
        company?: string
        contactPerson?: string
        mail?: string
        phone?: string
    }>({})
    const [contactFormMessage, setContactFormMessage] = useState<string>()

    const submitForm = (e: FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        axios
            .post("/contact-form", {
                ...formValues,
            })
            .then(() => {
                setContactFormMessage("Takk for din henvendelse! Vi kontakter deg innen kort tid.")
                setFormValues({})
            })
            .catch((err) => {
                setContactFormMessage(
                    "Her skjedde det en uventet feil. Forsikre deg om at alle felter er korrekt utfylt og prøv igjen.",
                )
            })
    }

    return (
        <div className={classes.root}>
            <Parallax
                overlayGradient={`linear-gradient(${fade(theme.palette.primary.main, 0.2)}, 
                    ${fade(theme.palette.secondary.main, 0.3)}), 
                    linear-gradient(${fade(theme.palette.common.white, 0.9)} 80%, 
                    ${fade(theme.palette.common.white, 0.6)})`}
                backgroundImage={CloseUpComputerImage}
                height={1}
                className={classes.fullCenter}
            >
                <Typography gutterBottom variant={"h1"} align={"center"}>
                    ODECON
                </Typography>
                <Typography gutterBottom variant={"h5"}>
                    Skreddersydde • Brukervennlige • Rimelige
                </Typography>
                <Typography gutterBottom variant={"h4"}>
                    IT-løsninger
                </Typography>
            </Parallax>
            <section className={cn(classes.newHomepageSection, "section")}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant={"h2"} gutterBottom>
                            Ny nettside?
                        </Typography>
                        <Typography variant={"h5"} gutterBottom>
                            Har din bedrift nådd sitt fulle potensiale når det kommer til synlighet på digitale flater?
                        </Typography>
                        <br />
                        <Typography variant={"h5"}>
                            Med ny nettside kan potensielle kunder se hvorfor de bør velge akkurat
                            <span style={{ fontStyle: "italic" }}>din</span> bedrift for jobben.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img
                            className={"imageShadow"}
                            alt={"Eksempel på nettside Odecon kan levere"}
                            src={ComputerWithHomepageImage}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                </Grid>
            </section>
            <section className={cn(classes.whyChooseUsSection, "section")}>
                <Typography variant={"h2"} gutterBottom>
                    Hvorfor velge oss?
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant={"h3"} align={"center"} gutterBottom>
                                    God service
                                </Typography>
                                <GroupIcon />
                                <Typography align={"center"}>
                                    Ved å holde en tett dialog gjennom hele utviklings-prosessen, utvikler vi løsninger
                                    som tilfredsstiller dine behov.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant={"h3"} align={"center"} gutterBottom>
                                    Smarte løsninger
                                </Typography>
                                <PhonelinkIcon />
                                <Typography align={"center"}>
                                    Med fokus på samhandling mellom ansatt, teknologi og kjernevirksomhet, utvikler vi
                                    morgendagens løsninger, sammen.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant={"h3"} align={"center"} gutterBottom>
                                    Fornuftige priser
                                </Typography>
                                <ChartIcon />
                                <Typography align={"center"}>
                                    Med god kompetanse leverer vi løsninger tilpasset behov, kapasitet og budsjett.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </section>
            <Parallax backgroundImage={ComputerWithGraph} height={1} className={classes.fullCenter}>
                <section className={"section " + classes.contactForm}>
                    <Card>
                        <form onSubmit={submitForm}>
                            <CardContent>
                                <Typography variant={"h2"} gutterBottom>
                                    Kontakt oss
                                </Typography>
                                <Typography gutterBottom variant={"subtitle1"}>
                                    {contactFormMessage ||
                                        "Send oss en uforpliktende forespørsel som beskriver dine utfordringer, så kommer vi tilbake til deg innen kort tid."}
                                </Typography>
                                <TextField
                                    value={formValues.description || ""}
                                    required
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setFormValues((currentFormValues) => ({
                                            ...currentFormValues,
                                            description: value,
                                        }))
                                    }}
                                    fullWidth
                                    rows={1}
                                    rowsMax={6}
                                    multiline
                                    label={"Beskrivelse/forespørsel"}
                                />
                                <TextField
                                    value={formValues.company || ""}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setFormValues((currentFormValues) => ({
                                            ...currentFormValues,
                                            company: value,
                                        }))
                                    }}
                                    fullWidth
                                    label={"Firma"}
                                />
                                <TextField
                                    value={formValues.contactPerson || ""}
                                    required
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setFormValues((currentFormValues) => ({
                                            ...currentFormValues,
                                            contactPerson: value,
                                        }))
                                    }}
                                    fullWidth
                                    label={"Kontaktperson"}
                                />
                                <TextField
                                    value={formValues.mail || ""}
                                    required
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setFormValues((currentFormValues) => ({
                                            ...currentFormValues,
                                            mail: value,
                                        }))
                                    }}
                                    fullWidth
                                    label={"E-post"}
                                />
                                <TextField
                                    value={formValues.phone || ""}
                                    onChange={(e) => {
                                        const { value } = e.target
                                        setFormValues((currentFormValues) => ({
                                            ...currentFormValues,
                                            phone: value,
                                        }))
                                    }}
                                    fullWidth
                                    label={"Telefonnummer"}
                                />
                            </CardContent>
                            <CardActions>
                                <Button size={"large"} color={"primary"} type={"submit"} variant={"contained"}>
                                    Send
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </section>
            </Parallax>
        </div>
    )
}

export default Landing
